<template>
  <b-container class="bv-example-row mb-3" fluid>
    <div>
      <b-card>
        <div id="printMe">
          <b-row>
            <b-col cols="4" class="top-row-col-4">
              <h4>Vardiya Konaklama Listesi</h4>
            </b-col>
            <b-col cols="8" class="text-right unPrint">
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="info"
                  :to="'/vardiyaOzet/' + vardiyaID"
                  class="myButton mr-50"
              >
                <feather-icon icon="GridIcon" class="mr-50"/>
                Vardiya Özeti
              </b-button>
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="info"
                  v-print="'#printMe'"
              >
                <feather-icon icon="PrinterIcon" class="mr-50"/>
                Konaklama Kayıtlarını Yazdır
              </b-button>
            </b-col>
          </b-row>
          <hr/>
          <table class="table table-striped mt-1 table-hover">
            <thead>
            <tr>
              <th scope="col">ODA</th>
              <th scope="col">REZERVASYON NO</th>
              <th width="30%" scope="col">KONUK İSMİ</th>
              <th scope="col">GİRİŞ TARİHİ</th>
              <th scope="col">ÇIKIŞ TARİHİ</th>
              <th scope="col">DURUM</th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="(kon, index) in this.$store.getters.getKonaklama"
                :key="index"
            >
              <td>
                <p>
                  {{ kon.odaKodu }}
                </p>
              </td>
              <td>
                <p>{{
                    kon.rezervasyonNo
                  }}</p>
              </td>
              <td>{{ kon.adi }} {{ kon.soyAdi }}</td>
              <td>{{ kon.girisTarih }}</td>
              <td>{{ kon.cikisTarih }}</td>
              <td>
                {{
                  kon.odemeDurum === 0
                      ? ' Tahsilat Bekliyor'
                      : '' || kon.odemeDurum === 1
                          ? ' Tahsilat Tam.'
                          : ''
                }}
                /{{
                  kon.checkOut === 0
                      ? ' Aktif Kon.'
                      : '' || kon.checkOut === 1
                          ? ' Çıkış Yapıldı'
                          : ''
                }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </b-card>
    </div>
  </b-container>
</template>

<script>
import {
  BRow,
  BCol,
  BContainer,
  BTable,
  BBadge,
  BForm,
  BButton,
  BCard,
  BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BContainer,
    BTable,
    BBadge,
    BForm,
    BButton,
    BCard,
    BSpinner,
  },
  data() {
    return {
      vardiyaID: this.$route.params.id,
    }
  },
  beforeDestroy() {
    this.$store.commit('konaklamaAction', 'remove')
  },
  created() {
    this.$store
        .dispatch('konaklamaKayitlari', this.vardiyaID)
        .then((res, position = 'bottom-right') => {
          const mesaj = this.$store.getters.notificationSettings(res)
          if (mesaj) {
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          }
        })
        .catch(err => {
          const mesaj = this.$store.getters.notificationSettings(err)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          }
        })
  },
  directives: {
    Ripple,
  },
}
</script>

<style scoped>
[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  background-color: #285274;
  color: white;
}

[dir] .table thead th {
  background-color: 2px solid #285274;
  padding: 10px !important;
}

[dir] .table th,
[dir] .table td {
  padding: 0 !important;
}

.myButton {
  color: white !important;
}

.myButton:hover {
  color: white !important;
}

a {
  color: #4595d7 !important;
}

a:hover {
  color: #6d62e4 !important;
}

.card-header {
  background-color: #2e83c9;
  height: 30px;
  position: relative;
}

.card-header h4 {
  position: absolute;
  color: white;
  margin-top: 10px;
}

.row {
  justify-content: space-between;
}

.top-row-col-4 {
  align-items: center;
  display: inherit !important;
}

[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  background-color: #0ea9d7;
  color: white;
}
</style>
